import { Controller } from '@hotwired/stimulus'
import { lock, unlock } from 'tua-body-scroll-lock'

export default class extends Controller {
  static targets = [ "imageDetails", "backgroundVariant", "imageVariant", "imageInput", "backgroundInput" ]

  connect () {
    this.validate();
  }

  chooseImage(event) {
    this.imageDetailsTarget.classList.add("active")
    lock(this.imageDetailsTarget)

    // select the image
    this.element.querySelectorAll(".image").forEach(image => {
      image.classList.remove("selected")
    });
    this.element.querySelector(`[data-id="${event.currentTarget.dataset.id}"]`).classList.add("selected")

    // show the image variants
    this.element.querySelectorAll(".image-variants").forEach(imageVariant => {
      imageVariant.classList.remove("active")
    });
    this.element.querySelector(`[data-image="${event.currentTarget.dataset.id}"]`).classList.add("active")

    this.element.querySelector('.image-details .content').scrollIntoView({
      behavior: 'smooth'
    });
  }

  chooseBackground(event) {
    this.backgroundVariantTargets.forEach(backgroundVariant => {
      backgroundVariant.classList.remove("selected")
    });
    event.currentTarget.classList.add("selected")
    this.backgroundInputTarget.value = event.currentTarget.dataset.id
    this.validate()
  }

  chooseVariant(event) {
    this.imageVariantTargets.forEach(imageVariant => {
      imageVariant.classList.remove("selected")
    });
    event.currentTarget.classList.add("selected")
    this.imageInputTarget.value = event.currentTarget.dataset.id
    this.validate()
  }

  validate() {
    if (this.imageInputTarget.value && this.backgroundInputTarget.value) {
      this.element.querySelector('button[type="submit"]').disabled = false
    } else {
      this.element.querySelector('button[type="submit"]').disabled = true
    }
  }

  back(event) {
    if (this.imageDetailsTarget.classList.contains("active")) {
      event.preventDefault()
      this.imageDetailsTarget.classList.remove("active")
      unlock(this.imageDetailsTarget)
    }
  }

}
